import API from "@/package/config/axios";
import { LocationQueryValue } from "vue-router";
import {
  IAwardResponse,
  ICartsResponse,
  ICartUpdateResponse,
  IPaymentMethodsResponse,
} from "../types/backend-types";

export const apiGetCarts = () => {
  return API.get<ICartsResponse>("api/v2/places/cart");
};

export const apiClearCart = (
  cartId: LocationQueryValue | LocationQueryValue[] | number
) => {
  return API.post(`api/v2/places/cart/${cartId}/clear`);
};

export const apiGetCartAward = (cartId: number) => {
  return API.get<IAwardResponse>(`api/v2/places/cart/${cartId}/award`);
};

export const apiGetCartPaymentMethods = (cartId: number) => {
  return API.get<IPaymentMethodsResponse>(
    `api/v2/places/cart/${cartId}/payment-methods`
  );
};

export const apiUpdateProductInCart = (params: {
  product_id: number;
  count: number;
}) => {
  return API.post<ICartUpdateResponse>("api/v2/places/cart/update", params);
};
