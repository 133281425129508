import { defineStore } from "pinia";
import { BASE_URL } from "@/package/config/axios";
import * as userApi from "../api/user-api";
import { ITotp, IUser } from "../types/frontend-types";
import { errorHandler } from "@/package/global-helpers/error-handler";
import { useCookies } from "@vueuse/integrations/useCookies";

const cookies = useCookies();

export const useUserStore = defineStore("user-store", {
  state: () => ({
    user: null as null | IUser,
    cardNumber: null as null | string,
    userTotp: null as null | ITotp,
  }),

  actions: {
    authUser() {
      window.open(`${BASE_URL}api/v2/oauth/redirect`, "_self");
    },

    exitUser() {
      cookies.remove("ksUserToken", {
        path: "/",
        domain: import.meta.env.VITE_HOST,
      });
      cookies.remove("ksUserRefresh", {
        path: "/",
        domain: import.meta.env.VITE_HOST,
      });
      window.location.reload();
    },

    async loadUserInformation() {
      try {
        const resp = await userApi.apiGetUserInformation();
        this.user = resp.data.data.user;
      } catch (error: Error | unknown) {
        errorHandler(error, true);
        throw error;
      }
    },

    setUserPromoCode(code: string) {
      this.user.promocode = code;
    },

    async loadUserTotp() {
      try {
        const resp = await userApi.apiGetUserTotp();
        this.cardNumber = resp.data.data.card_number;
        this.userTotp = resp.data.data.totp;
      } catch (error: Error | unknown) {
        errorHandler(error, false);
        throw error;
      }
    },

    async urlRedirect(params: any) {
      try {
        const resp = await userApi.apiUrlRedirect(params);
        return resp.data.data.redirect_to;
      } catch (error: Error | unknown) {
        errorHandler(error, false);
        throw error;
      }
    },

    clearUserTotp() {
      this.cardNumber = null;
      this.userTotp = null;
    },
  },
});
