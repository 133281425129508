import * as cartAPI from "../api/cart-api";
import { defineStore } from "pinia";
import { ICart } from "../types/frontend-types";
import { errorHandler } from "@/package/global-helpers/error-handler";

export const useCartStore = defineStore("cart-store", {
  state: () => ({
    cartsLoadingStatus: "loading" as "loading" | "loaded",
    carts: [] as Array<ICart>,
    cart: null as null | ICart,
  }),

  getters: {
    getCartsLength: (state) => {
      return state.carts.length;
    },
    getCartProductsLength: (state) => {
      const products = state.carts.map((cart) => cart.products).flat();
      let sum = 0;
      products
        .map((product) => product.count_in_cart)
        .map((item) => (sum += item));
      return sum;
    },
  },

  actions: {
    async loadCarts() {
      this.cartsLoadingStatus = "loading";
      try {
        const resp = await cartAPI.apiGetCarts();
        this.carts = resp.data.data.place_carts;
      } catch (error: Error | unknown) {
        errorHandler(error, true);
        throw error;
      } finally {
        this.cartsLoadingStatus = "loaded";
      }
    },

    async updateCartProduct(params: { product_id: number; count: number }) {
      try {
        const resp = await cartAPI.apiUpdateProductInCart(params);
        this.carts = resp.data.data.place_carts;
      } catch (error: Error | unknown) {
        errorHandler(error, true);
        throw error;
      }
    },

    async loadCartAward(cartId: number) {
      try {
        const resp = await cartAPI.apiGetCartAward(cartId);
        return resp.data.data.award;
      } catch (error: Error | unknown) {
        errorHandler(error, false);
        throw error;
      }
    },

    async loadCartPaymentMethods(cartId: number) {
      try {
        const resp = await cartAPI.apiGetCartPaymentMethods(cartId);
        return resp.data.data.payment_methods;
      } catch (error: Error | unknown) {
        errorHandler(error, false);
        throw error;
      }
    },

    async clearCart(cartId: number) {
      try {
        await cartAPI.apiClearCart(cartId).then(() => {
          this.loadCarts();
        });
      } catch (error: Error | unknown) {
        errorHandler(error, true);
        throw error;
      }
    },
  },
});
